import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import clsx from 'clsx';
// styles
import styles from './developers-cv.module.css';
import achievmentStyles from 'components/recognition/achievements/achievements.module.css';
// components
import Header from '../components/header';
import Layout from 'components/layout';
import Footer from 'components/layout/footer';
import Booking from 'components/feedback';
import Facts from 'components/landing-pages/developers-cv/facts';
import Developers from 'components/landing-pages/components/developers';
import LinksContainer from 'components/technologies/components/linksContainer';
import Projects from 'components/landing-pages/components/projects';
import Reviews from './reviews';
import BenefitsTable from 'components/recognition/benefits-table';
//meta
import Identificators from 'components/googleAnalyticsIdentificators';
import { meta } from 'metaData';
// assets
import Github from 'images/github.inline.svg';
import Star from 'images/star.inline.svg';

const Routes = require('../../routes').Routes;

const developersCV = () => {
  const [starsCount, setStarsCount] = useState('1');
  useEffect(() => {
    fetch('https://api.github.com/repos/brocoders/nestjs-boilerplate')
      .then((response) => response.json())
      .then((data) => {
        setStarsCount(data.stargazers_count);
      })
      .catch((error) => {
        throw Error(`Error fetching nestjs-boilerplate repository information: ${error}`);
      });
    return () => {};
  }, []);
  const bookingRef = useRef<HTMLDivElement>(null);
  const handleClickCTA = () => {
    if (bookingRef.current !== null) {
      bookingRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };
  const data = useStaticQuery(graphql`
    query {
      broLogo: file(relativePath: { eq: "bro-logo-red.svg" }) {
        publicURL
      }
      heypractice: file(relativePath: { eq: "heypractice.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      adact: file(relativePath: { eq: "adact.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      soplan: file(relativePath: { eq: "soplan.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const projects = [
    {
      image: data.heypractice.childImageSharp.fluid,
      description: 'AI-based Interactive learning platform for Sales and Marketing teams',
      industries: 'SaaS/EdTech',
      stack: '• Node.js • AWS • React.js • Precire • IBM Watson',
      points: [
        'AI-powered speech recognition',
        'SCORM standard implementation',
        'AI feedback (PRECIRE)',
        'Admin panel',
        'Users and role menagement',
        'Courses creation, dashboard and analytics',
      ],
      linkToCase: `${Routes.CASE_STUDIES_HEYPRACTICE}`,
      linkId: `${Identificators.LEARN_MORE_HEYPRACTICE}`,
    },
    {
      image: data.soplan.childImageSharp.fluid,
      description: 'The social event planning app for friends, families and local businesses.',
      industries: 'SaaS/Productivity',
      stack: '• Node.js • AWS • React Native',
      points: [
        'Serverless architecture',
        'Crossplatform mobile app',
        'Scheduling assistant feature',
        'Events management',
        'Admin panel',
        'Push notifications',
      ],
    },
    {
      image: data.adact.childImageSharp.fluid,
      description:
        "World's leading MarTech software that allows the creation of fully customizable interactive experiences",
      industries: 'SaaS/Martech',
      stack: '• Node.js • AWS • React.js',
      points: [
        'Real-time analytics',
        'Server-side renderring',
        'Multi-user support',
        'Dashboard analytics',
        'Cost-effective infrastructure',
        'High-loaded system',
      ],
      linkToCase: `${Routes.CASE_STUDIES_ADACT}`,
      linkId: `${Identificators.LEARN_MORE_ADACT}`,
    },
  ];
  const benefits = [
    {
      point: '87',
      description: 'In-house \n software engineers',
    },
    {
      point: '10000',
      description: 'Candidates \n in our database',
    },
    {
      point: "5-7<span style='font-size: 36px; letter-spacing: -1px;'>days</span>",
      description: 'To get an expert \n with the needed expertise',
    },
    {
      point: "94<span style='font-size: 36px; letter-spacing: -1px;'>%</span>",
      description: 'Client \n satisfaction rate',
    },
  ];

  return (
    <Layout metaData={{ main: meta.saasDevs }}>
      <Header>
        <h1 className="header-title">Hire TOP SaaS Developers</h1>
        <p className="header-text">
          Build a committed team or fill the required positions with the right talent — Brocoders
          has professional developers to start your SaaS project without delays.
        </p>
        <LinksContainer
          id={`${Identificators.HEADER_HIRE_SAAS_ENGINEERS}`}
          onCtaClick={handleClickCTA}
        />
      </Header>
      <main className={styles.main}>
        <section className="grey-section">
          <div className="inner">
            <Facts />
          </div>
        </section>
        <section className="inner">
          <div className={styles.container}>
            <div className={styles.devForeword}>
              <img src={data.broLogo.publicURL} className={styles.logoImg} alt="" />
              <p>
                Here at Brocoders we’ve picked up and share with you <b> TOP engineers </b> with
                experience in building <b> SaaS products </b> so you can select the perfect match
                for your requirements
              </p>
            </div>
            <Developers />
          </div>
        </section>
        <section className="grey-section">
          <div className="inner">
            <div className={styles.container}>
              <h2 className={clsx(styles.devTitle, styles.centered)}>
                Why SaaS engineers from Brocoders
              </h2>
              <ul className={clsx(styles.devBenefits, achievmentStyles.benefits)}>
                <li className={achievmentStyles.benefitsItem} key="quality of code proved">
                  <div className={achievmentStyles.daysBox}>
                    <span className={achievmentStyles.point}>94</span>
                    <span className={achievmentStyles.days}>%</span>
                  </div>
                  <span className={achievmentStyles.description}>
                    {'Quality of code proved \n by client \n satisfaction rate'}
                  </span>
                </li>
                <li className={achievmentStyles.benefitsItem} key="Senior engineers">
                  <div className={achievmentStyles.daysBox}>
                    <span className={achievmentStyles.point}>8</span>
                    <span className={achievmentStyles.days}> years</span>
                  </div>
                  <span className={achievmentStyles.description}>
                    {'Senior engineers \n with at least experience'}
                  </span>
                </li>
                <li className={achievmentStyles.benefitsItem} key="Nest.js Boilerplate">
                  <div className={styles.gitStars}>
                    <Star />
                    <span className={styles.gitStarsCount}>{starsCount} on GitHub</span> <Github />
                  </div>
                  <span className={achievmentStyles.description}>
                    {'React.js and Nest.js  \n boilerplates made by  \n Brocoders team'}
                  </span>
                </li>
                <li className={achievmentStyles.benefitsItem} key="Projects on production">
                  <span className={achievmentStyles.point}>85 </span>
                  <span className={achievmentStyles.description}>
                    {'Projects \n on productiont'}
                  </span>
                </li>
              </ul>
              <Link
                className={clsx('btn btn_60 btn__red wide__btn', styles.btn)}
                id={`${Identificators.CONTACT_US_FOR_MORE_CANDIDATES}`}
                to={`${Routes.GET_IN_TOUCH}`}
              >
                Contact us for more candidates
              </Link>
            </div>
          </div>
        </section>
        <section className="inner">
          <div className={styles.container}>
            <h2 className={styles.devTitle}>{'SaaS projects \n They Been \n Working On'}</h2>
            <p className={styles.lightParagraph}>
              Examples from portfolio of our TOP-picked engineers
            </p>
            <Projects projects={projects} />
          </div>
        </section>
        <section className="section">
          <Reviews />
        </section>
        <section className="inner">
          <div className={styles.container}>
            <BenefitsTable list={benefits} />
          </div>
        </section>
        <div className={styles.booking}>
          <Booking ctaRef={bookingRef} />
        </div>
      </main>
      <Footer />
    </Layout>
  );
};

export default developersCV;
