import React from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';
import Img, { FluidObject } from 'gatsby-image';
// styles
import styles from '../../developers-cv/developers-cv.module.css';

interface IProjects {
  projects: {
    image: FluidObject;
    description: string;
    industries: string;
    stack: string;
    points: string[];
    linkToCase?: string;
    linkId?: string;
  }[];
}

const Projects = ({ projects }: IProjects) => {
  return (
    <ul className={styles.projects}>
      {projects.map(
        ({ image, description, industries, stack, points, linkToCase, linkId = '' }) => (
          <li className={styles.projectItem} key={description}>
            <Img fluid={image} alt="case cover" className={styles.projectImg} />
            <div className={styles.projectDescription}>
              <p className={styles.projectAbout}>{description}</p>
              <p className={styles.industries}>{industries}</p>
              <p className={styles.stack}>{stack}</p>
              <ul className={styles.pointList}>
                {points.map((point) => (
                  <li className={styles.pointItem} key={point}>
                    {point}
                  </li>
                ))}
              </ul>
              {linkToCase && (
                <Link
                  to={linkToCase}
                  className={clsx('btn btn_40 btn__red', styles.learnBtn)}
                  id={linkId}
                >
                  Learn more
                </Link>
              )}
            </div>
          </li>
        )
      )}
    </ul>
  );
};

export default Projects;
