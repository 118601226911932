import React, { memo } from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import clsx from 'clsx';
import styles from '../../developers-cv/developers-cv.module.css';
import SendCvModal from 'components/pop-ups/send-cv-modal';
import VideoModal from '../../../pop-ups/video-modal';
//icons
import PlayBtn from '../../../../images/career/pink-btn.inline.svg';
//hooks
import useModal from 'hooks/useModal';

function Developers() {
  const data = useStaticQuery(graphql`
    query {
      artem: file(relativePath: { eq: "developers/artem.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      luda: file(relativePath: { eq: "developers/lyudmila.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      yaroslav: file(relativePath: { eq: "developers/yaroslav-r.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const { handleModal } = useModal();
  const devCards = [
    {
      name: 'Artem',
      image: data.artem.childImageSharp.fluid,
      availability: 'busy till the August, 24',
      experience: '9 years of development',
      stack: '• Node.js • AWS • Nest.js • Typescript',
      info: 'Senior Fullstack Developer with a huge experience in building scalable SaaS architecture',
      linkedForm: `${process.env.GATSBY_HS_FORM_ARTEM_CV}`,
      video: 'Tema_result',
    },
    {
      name: 'Lyudmila',
      image: data.luda.childImageSharp.fluid,
      availability: 'available now',
      experience: '5 years of development',
      stack: '• React.js • React Native • Typescript',
      info: 'React and React Native developer with experience in building Mobile SaaS Platforms',
      linkedForm: `${process.env.GATSBY_HS_FORM_LYUDMILA_CV}`,
      video: 'lyudmila-k',
    },
    {
      name: 'Yaroslav',
      image: data.yaroslav.childImageSharp.fluid,
      availability: 'available now',
      experience: '5 years of development',
      stack: '• React.js • AWS • Node.js • Nest.js • Redux',
      info: 'Fullstack developer specializing in development of SaaS applications for Health Care, Education, and Finance',
      linkedForm: `${process.env.GATSBY_HS_FORM_YAROSLAV_CV}`,
      video: 'yaroslav-r',
    },
  ];

  return (
    <ul className={styles.devBox}>
      {devCards.map(({ name, image, availability, experience, stack, info, linkedForm, video }) => (
        <li className={styles.devCard} key={name}>
          <div
            className={styles.devImgThumb}
            onClick={() =>
              handleModal(
                <VideoModal
                  source={`${process.env.GATSBY_STRAPI_CDN}/video/${video}`}
                  preload="auto"
                  autoPlay={true}
                  controls
                  width="100%"
                  height="auto"
                />,
                false
              )
            }
          >
            <Img fluid={image} className={styles.img} alt="poster of video" />
            <PlayBtn className={styles.playBtn} />
          </div>
          <div className={styles.devInfo}>
            <p
              className={clsx(
                styles.devAvailability,
                availability === 'available now' && styles.avalible
              )}
            >
              {availability}
            </p>
            <p className={styles.infoTitle}>{experience}</p>
            <p className={styles.infoText}>{stack}</p>
            <p className={styles.devName}>{name}</p>
            <p className={styles.infoText}>{info}</p>
            <button
              className={clsx('btn btn_40 btn__red', styles.learnBtn)}
              onClick={() => handleModal(<SendCvModal formId={linkedForm} />, false)}
            >
              Download CV
            </button>
          </div>
        </li>
      ))}
    </ul>
  );
}

export default memo(Developers);
