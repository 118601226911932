import * as React from 'react';
import clsx from 'clsx';
import styles from './header.module.css';
import Wave from 'pages/components/header/wave';

interface Props {
  children: React.ReactNode;
  bcgColor?: string;
}

function Header({ children, bcgColor = 'var(--nav-invers-color)' }: Props) {
  return (
    <header className={styles.container} style={{ backgroundColor: bcgColor }}>
      <div className={styles.wrapper}>
        <div className={clsx('inner-container', styles.contentContainer)}>
          <div className={styles.content}>{children}</div>
        </div>
        <Wave color="#ffffff" opacity={0.08} />
      </div>
    </header>
  );
}

export default Header;
