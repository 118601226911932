import React, { useContext } from 'react';
//styles
import styles from './send-cv.module.css';
import HubspotCV from 'components/landing-pages/developers-cv/hubsportCvForm';
// context
import { ModalContext } from 'components/modal/index';
//icons
import CloseBtn from '../../../images/career/close-form.inline.svg';

export interface ISendCvModal {
  formId: string;
}

function SendCvModal({ formId }: ISendCvModal) {
  const { closeModal } = useContext(ModalContext);
  const id = formId;

  const onKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.code === 'Escape') {
      closeModal();
    }
  };
  const closeOnSubmit = () => {
    setTimeout(() => {
      closeModal();
    }, 5000);
  };

  return (
    <div className={styles.container} onKeyDown={onKeyDown}>
      <p className={styles.text}>We’ll send CV right to your inbox</p>
      <HubspotCV formId={id} onSubmit={closeOnSubmit} />
      <div className={styles.closeBtn} onClick={closeModal}>
        <CloseBtn />
      </div>
    </div>
  );
}

export default SendCvModal;
