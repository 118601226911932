import React from 'react';

type WindowWithHubspot = Window & {
  hbspt: any;
};

interface IFormCV {
  formId: string;
  onSubmit: () => void;
}

declare const window: WindowWithHubspot;
const isBrowser = () => typeof window !== 'undefined';

const MESSAGE_ID = 'CV_Form';

export default function HubspotCV({ formId, onSubmit }: IFormCV) {
  React.useEffect(() => {
    if (isBrowser()) {
      const script = document.createElement('script');
      script.src = `${process.env.GATSBY_HS_FORM_SRC}`;
      script.type = 'text/javascript';
      script.async = true;
      document.body.appendChild(script);

      script.addEventListener('load', () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            target: `#${MESSAGE_ID}`,
            region: `${process.env.GATSBY_HS_REGION}`,
            portalId: `${process.env.GATSBY_HS_PORTAL_ID}`,
            formId: formId,
            onFormSubmitted: onSubmit,
          });
        }
      });
    }
  }, []);

  return <div id={MESSAGE_ID}></div>;
}
