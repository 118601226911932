import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import clsx from 'clsx';
import Img from 'gatsby-image';
// styles
import styles from '../developers-cv.module.css';
// components

const Facts = () => {
  const data = useStaticQuery(graphql`
    query {
      aim: file(relativePath: { eq: "industries/aim-mob.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      team: file(relativePath: { eq: "team-group.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const breakpoint = useBreakpoint();

  return (
    <div className={styles.container}>
      <p className={styles.factsTitle}>Did you know that</p>
      <ul className={styles.factslist}>
        <li className={styles.factsBox} key="candidates proposed by outsourcing">
          <p className={clsx('red', styles.facts)}>70%</p>
          <p className={styles.challengesText}>
            of candidates proposed by outsourcing agencies waste client time and end up being
            rejected?
          </p>
          <Img fluid={data.aim.childImageSharp.fluid} className={styles.aimImg} alt="" />
        </li>
        <li className={styles.factsBox} key="this is because">
          <div className={styles.factsCondition}>
            <p className={styles.factsText}>This is because </p>
            <p className={clsx(styles.facts, styles.emphasis)}>90%</p>
          </div>
          <p className={styles.challengesText}>
            of those people are on the "bench" and just need to be "utilized" regardless the
            matching clients requirements
          </p>

          <Img fluid={data.team.childImageSharp.fluid} className={styles.aimImg} alt="" />
        </li>
      </ul>
    </div>
  );
};

export default Facts;
