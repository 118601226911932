import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import loadable from '@loadable/component';
// components
import Blockquote from 'components/services/components/blockquote';

const ReviewSlider = loadable(() => import('components/swiper-slider/review-slider'));
const Reviews = () => {
  const data = useStaticQuery(graphql`
    query {
      rafalDyrda: file(relativePath: { eq: "rafal-dyrda.png" }) {
        childImageSharp {
          fluid(maxWidth: 430, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      condogenie: file(relativePath: { eq: "case-logos/condogenie.svg" }) {
        publicURL
      }
      kalev: file(relativePath: { eq: "kalev-adact.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      adactLogo: file(relativePath: { eq: "img/adact-black-logo.svg" }) {
        publicURL
      }
      davidNeuendorf: file(relativePath: { eq: "david-neuendorf.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heyPractice: file(relativePath: { eq: "hey-practice.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <ReviewSlider>
      <Blockquote
        text="“With Brocoders, we were able to set up a highly skilled IT team in the shortest amount of time. For finding a team with similar skills we would have needed 4-6 months instead of the instant start.”"
        img={data.davidNeuendorf.childImageSharp.fluid}
        logoImg={data.heyPractice.publicURL}
        isLogo
        isRating
        author={
          <React.Fragment>
            <strong>David Neuendorf </strong> <br />
            CEO,{' '}
            <a href="https://www.heypractice.com/de/" target="_blank" rel="noopener noreferrer">
              HeyPractice.com
            </a>
          </React.Fragment>
        }
      />
      <Blockquote
        img={data.rafalDyrda.childImageSharp.fluid}
        isRating
        isLogo
        logoImg={data.condogenie.publicURL}
        text={`"I had very great communication with the team. Upon launching the project, we were able to increase client satisfaction and provide our clients what they were asking for, improve their workflow as well as make the software more powerful and easier to use than the previous software that was developed."`}
        author={
          <React.Fragment>
            <strong>Rafal Dyrda</strong> <br />
            CEO and Founder,{' '}
            <a href="https://www.condogenie.com" target="__blank" className="link">
              CondoGenie
            </a>
          </React.Fragment>
        }
      />
      <Blockquote
        img={data.kalev.childImageSharp.fluid}
        isRating
        logoImg={data.adactLogo.publicURL}
        isLogo
        text='"We’ve tested the product and have acquired over two million interactions without experiencing downtime or bug-related issues, so I can confidently say that Brocorders has met our success criteria."'
        author={
          <React.Fragment>
            <b>Kalev Kärpuk</b>
            <br />
            CEO &amp; Founder,{' '}
            <a href="https://adact.me/" target="_blank" className="link">
              Adact
            </a>
          </React.Fragment>
        }
      />
    </ReviewSlider>
  );
};

export default Reviews;
